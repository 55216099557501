.root{
   padding-top:10rem !important;
   padding-bottom: 15rem !important;
   padding-left: 6rem !important;
 }
    
 .label {
   text-align: "center" !important;
   font-weight: 900 !important;
   font-size: 25rem !important;
   line-height: 1 !important;
   /* marginBottom: `calc(${theme.spacing.xl} * 1.5)` !important; */
   /* color:
     theme.colorScheme === "dark"
       ? theme.colors.dark[4]
       : theme.colors.gray[2] !important;
     */
   /* [theme.fn.smallerThan("sm")]: {
     font-size: rem(120) !important;
   } !important; */
 }
    
 .title {
   /* fontFamily: `Greycliff CF !important; ${theme.fontFamily}` !important; */
   text-align: "center" !important;
   font-weight: 900 !important;
   font-size: rem(38) !important;
    
   /* [theme.fn.smallerThan("sm")]: {
     font-size: rem(32) !important;
   } !important; */
 }
    
 .description{
   max-width: rem(500) !important;
   margin: "auto" !important;
   margin-top: 1rem !important;
   margin-bottom: 1rem !important;
 }