:root {
  color-scheme: var(--mantine-color-scheme) o  !important;
}

.lightAppShellMain{
  background-color: #F5F6FA !important;
  height: 100%;
  padding-bottom: 0 !important;

}


.darkAppShellMain{
  padding-bottom: 0 !important;
}

.lightnavbar {
  /* background-color: #313A46 !important; */
  height: rem(800px) !important;  
  width: rem(300px) !important;
  padding: var(--mantine-spacing-md) !important;
  padding-bottom: 0 !important;
  display: flex !important;
  flex-direction: column !important;
  /* border-right: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) !important; */
}

.darkNavbar{
background-color: #272B2F !important;
}

.header {
  padding: var(--mantine-spacing-md) !important;
  padding-top: 0 !important;
  margin-left: calc(var(--mantine-spacing-md) * -1) !important;
  margin-right: calc(var(--mantine-spacing-md) * -1) !important;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white)) !important;
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) !important;
}

.lightHeader{
background-color: #fff !important;
}

.darkHeader{
background-color: #272B2F !important;
}

.links {
  flex: 1 !important;
  margin-left: calc(var(--mantine-spacing-md) * -1) !important;
  margin-right: calc(var(--mantine-spacing-md) * -1) !important;
}

.linksInner {
  padding-top: var(--mantine-spacing-xl) !important;
  padding-bottom: var(--mantine-spacing-xl) !important;

}

.footer {
  margin-left: calc(var(--mantine-spacing-md) * -1) !important;
  margin-right: calc(var(--mantine-spacing-md) * -1) !important;
  border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) !important;
}


.TitleItem{
  background-color: light-dark(#ffffff, #000) !important;

    color: light-dark(#fff, var(#000)) !important;
    padding-bottom: 0,
  }

  .titleLight{
      color: #000  !important;
  }

  .titleDark{
    color: #fff  !important;
}

.lightMenuLabel{
  color: #08080aee !important;
}

.darkMenuLabel{
  color: #fff !important;

}

.darkFooter{
background-color: #272B2F !important;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2) !important;
}

.lightFooter{
  background-color: #fff !important;
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.2) !important;
  }