.lightControl {
  font-weight: 500 !important;
  display: block !important;
  width: 90% !important;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md) !important;
  color: var(--mantine-color-text) !important;
  font-size: var(--mantine-font-size-sm) !important;
  transition: 0.1s ease-in !important;
  border-radius: 5px !important;
  margin-left: 10px !important;


  &:hover { 
    transform: scale(1.01) !important;
    -webkit-transform: scale(1.01) !important;
    background-color: #817f7f28 !important;
    transition: 0.1s ease-out;
   }

  &[data-active] {
    /* margin: 10px !important; */
    border-radius: 5px !important;
    &,
    &:hover {
      /* box-shadow: var(--mantine-shadow-sm) !important; */
      background-color: #635f5f41 !important;
      color: var(--mantine-color-blue-6) !important;
    }
  }
}

.darkControl {
  font-weight: 500 !important;
  display: block !important;
  width: 90% !important;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md) !important;
  color: var(--mantine-color-text) !important;
  font-size: var(--mantine-font-size-sm) !important;
  transition: 0.1s ease-in !important;
  margin-left: 10px !important;

  &:hover { 
    transform: scale(1.02) !important;
    -webkit-transform: scale(1.02) !important;
    background-color: #57545441 !important;
    transition: 0.1s ease-out;
   }

  &[data-active] {
    /* margin: 10px !important; */
    border-radius: 5px !important;
    &,
    &:hover {
      /* box-shadow: var(--mantine-shadow-sm) !important; */
      background-color: var(--mantine-color-dark-1) !important;
      color: var(--mantine-color-blue-6) !important;
    }
  }
}

.link {
  font-weight: 500 !important;
  display: block !important;
  text-decoration: none !important;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md) !important;
  padding-left: var(--mantine-spacing-md) !important;
  margin-left: var(--mantine-spacing-xl) !important;
  font-size: var(--mantine-font-size-sm) !important;
  color: #a3a6aaf3 !important;
  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) !important;
  width: 230px;

  &:hover {
    /* background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7)) !important; */
  }

  &[data-active] {
    &,
    &:hover {
      color: #fff !important;
    }
  }
}

.lightLink {
  font-weight: 500 !important;
  display: block !important;
  text-decoration: none !important;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md) !important;
  padding-left: var(--mantine-spacing-md) !important;
  margin-left: var(--mantine-spacing-xl) !important;
  font-size: var(--mantine-font-size-sm) !important;
  /* color: #a3a6aaf3 !important; */
  border-left: 1px solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4)) !important;
  width: 230px;

  &:hover {
    background-color: #817f7f28 !important;
  }

  /* &[data-active] {
    &,
    &:hover {
      color: #fff !important;
    }
  } */
}

.lightChevron {
  transition: transform 200ms ease !important;
 color: #a3a6aaf3 !important;

  &[data-active] {
    margin: 10px !important;
    border-radius: 5px !important;
    &,
    &:hover {
      /* box-shadow: var(--mantine-shadow-sm) !important; */
      color: #fff !important;
    }
  }
}