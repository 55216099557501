
.mainGrid{
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 60px ;
}

.dashboardGrid{
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    width: 100% !important;
    height: 100% !important;
    margin-bottom : 100px !important;
}

.mainTitleCol{
    display: flex !important;
    justify-content: space-between !important;
    width: 100% !important;
    height: 60px !important;
    align-items: center !important;
    margin-bottom: 20px !important;
    /* color:#63bada; */
    /* color:#64cce9a9 */
    /* background-color: "linear-gradient(to right,#283048,#859398)", */
}

.mainTitle{
    font-size: 30px !important;
    font-weight: 700 !important;
    letter-spacing: 1px !important;
    padding-left: 10px !important;
    padding-right: 50px !important;
    padding-top: 20px !important;
}

.mainNav{
    /* position: relative !important;
    top: 1px !important; */
    width: 100% !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
    /* justify-content: flex-end !important; */
    /* padding-left: 15px ; */
    /* padding-right: 50px !important; */
    /* justify-content: space-between !important; */
}

.mainNavButton{
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
    width: 120px !important;
    height: 40px !important;
    border-radius: 5px !important;
    cursor: pointer !important;
    transition: 0.3s ease-in;

}

.mainNavButton:hover{
    transform: scale(1.02);
    -webkit-transform: scale(1.02);
    transition: 0.3s ease-in-out;
}

.mainCard{
    margin: 20px !important;
    height: auto !important;
    min-height: 350px !important;
    width: 100% !important;
    overflow-y: scroll !important;
}

.deviceMainCard{
    /* margin:  50px 50px 10px 50px !important; */
    margin-top: 10px !important;
    max-height: 70vh !important;
    /* min-height: 650px !important; */
    width: 100% !important;
    min-height: calc(85vh - 200px) !important;
}

.deviceTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deviceMainGridCol{
    overflow-y: scroll !important;
    /* display: flex !important;
    flex-wrap: nowrap !important; */
     /* min-height: 40%  !important;
     max-height: 40% !important; */
     /* height: 60% !important; */

}

.deviceMainGridCol::-webkit-scrollbar {
    width: 0.1em !important;
    background-color: transparent !important;
  }
  
.deviceMainGridCol::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}



.deviceComponentCard{
    margin: 5px !important;
    margin-top: 1 !important;
    padding: 2 !important;
    align-items: center !important;
    border-radius: 5 !important;
    transition: box-shadow 0.3s ease-in !important;
}

.buttonListGrid{
    display: flex !important;
    flex-wrap: nowrap !important;
    background-color: grey !important;
    width: 100% !important;
    margin-top: 30px !important;
    /* justify-content: center !important; */
}

.buttonListGridCol{
    min-height: 70px !important;
    padding: 10px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 5px !important;
    justify-content: center !important;
    align-items: center !important;
}

.outletCard{
    height: auto !important;
    min-height: 60vh !important;
    width: 100% !important;
    margin-bottom: 50px !important;
}

.itemMainCard{
    /* min-height: 200px !important; */
    margin: 5px !important;
    height: 60vh !important;
    min-width: 100% !important;
    overflow-y: scroll !important;
    min-height: calc(60vh - 200px) !important;
}

.itemMainCard::-webkit-scrollbar {
    width: 0.1em !important;
    background-color: transparent !important;
  }
  
.itemMainCard::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}

.itemCard{
    /* margin: 2px !important; */
    height: auto !important;
    min-width: 300px !important;
    width: auto !important;
    /* display: flex !important; */
    /* flex-direction: row !important; */
    transition: 0.2s ease-in-out;

}


.itemListMainCard{
    padding: 0px  !important;
    min-height: 200px !important;
    height: 70vh !important;
    max-height: 70vh !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    overflow-y: scroll !important;
    margin-top: 15px !important;
}

.itemListGrid{
    gap: 10px !important;
    overflow-y: scroll !important;
    width: 100% !important; 
    padding: 10px !important;
}


.itemListMainCard::-webkit-scrollbar,.itemListGrid::-webkit-scrollbar {
    width: 0.1em !important;
    background-color: transparent !important;
  }
  
.itemListMainCard::-webkit-scrollbar-thumb,.itemListGrid::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}


.itemListCard{
    /* margin: 10px !important; */
    height: 100px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    transition: 0.2s ease-in;

}

.itemListCard:hover{
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    transition: 0.3s ease-in-out;
    cursor:  pointer;

}

.itemListEditButton{
    /* color: #1971C2 !important; */
    height: 50px !important;
    width: 50px !important;
    padding: 15px !important;
    align-self: center !important;
    border-radius: 50% !important;
}

.itemListEditButton:hover{
    background-color: #c7bdbd3f !important;

}

.itemButtonDark{
    background-color: #57545441 !important;
    color: #000 !important;
    height: 45px !important;
    width: 45px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-right: 25px !important;
}

.itemButtonLight{
    background-color:transparent !important;
    color: #fff !important;
    height: 45px !important;
    width: 45px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-items: center !important;
    padding-right: 25px !important;
}

.itemButtonLight:hover{
    background-color: #c7bdbd3f !important;
}


.switchButtonInactive{
    background-color: gray !important;
}

.OutletAccordionGrid{
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    padding: 20px !important;
 
}

.mobileOuletView{
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    height: 100% !important;
    flex-wrap: wrap !important;
}

.desktopOutletView{
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 20px !important;
}

.outletInput{
    width: 100% !important;
    position: relative !important;
}

.mantineLabel{
    display: flex !important;
    justify-items: flex-start !important;
    gap: 5px !important;
    white-space: nowrap !important;
}

.settingsBox{
    min-width: 30% !important;
    max-width: 30% !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    border-radius: 10px !important;
    height: 100px !important;
}

.settingsCard{
    min-height: 400px !important;
    height: 65vh !important;
    display: flex !important;
    justify-content: center !important;
    overflow-y: hidden !important;
    max-height: 65vh !important;
}

.hfCard{
    /* min-height: 400px !important;
    height: 65vh !important; */
    display: flex !important;
    justify-content: flex-start !important;
    /* overflow-y: scroll !important;
    max-height: 65vh !important; */

}

.hfCard::-webkit-scrollbar {
    width: 0.1em !important;
    background-color: transparent !important;
  }

.hfCard::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}

.settingsBox{
    display: flex !important;
    flex-direction: row !important;
    min-width: 280px !important;
    max-width: 400px !important;
}

.settingsGrid{
    display: flex !important;
    flex-direction: column !important;
    overflow-y: scroll !important;
}

.settingsGrid::-webkit-scrollbar {
    width: 0.1em !important;
    background-color: transparent !important;
  }
  
.settingsGrid::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}


.settingsGridCol{
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    width: 100% !important;
    padding: 20px !important;
}

.settingsDataCol{
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    gap: 20px !important;
    flex-wrap: wrap !important;
}

.lightToolTip{
    background-color: #6e6565b7 !important;
    color: white !important;
}

.darkToolTip{
    background-color: #635b5b77 !important;
    color: rgba(255, 255, 255, 0.719) !important;
}

.modalCloseButton{
    background-color:  auto;
    border-radius: 50% !important;
    transition: 0.2s ease-in;

}

.modalCloseButton:hover{
    background-color:  var(--mantine-color-red-7) !important;
    color: #fff !important;
    transition: 0.2s ease-in-out;
}


.logoRestartText{
    color : rgb(243,235,11) !important;
    font-size: 1rem !important;
    font-family: cursive !important;
    animation: blink 4s linear infinite !important;
    width: 100% !important;
    text-align: center !important;
    margin: 10px !important;
    background-color: #000000a6 !important;
}
@keyframes blink{
    25%{opacity: 0;}
    50%{opacity: .5;}
    100%{opacity: 1;}
}

.deviceContent::-webkit-scrollbar {
    width: 0.1em !important;
    background-color: transparent !important;
  }
  
.deviceContent::-webkit-scrollbar-thumb {
    background-color: transparent !important;
}
.deviceActivationLabel{
font-weight: 400;
font-size: 12px;
}