.tab {
    position: relative;
    border: rem(1px) solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-6));
  
    &:first-of-type {
      border-radius: rem(4px) 0 0 rem(4px);
  
      @mixin rtl {
        border-radius: 0 rem(4px) rem(4px) 0;
      }
    }
  
    &:last-of-type {
      border-radius: 0 rem(4px) rem(4px) 0;
  
      @mixin rtl {
        border-radius: rem(4px) 0 0 rem(4px);
      }
    }
  
    & + & {
      border-left-width: 0;
  
      @mixin rtl {
        border-right-width: 0;
        border-left-width: rem(1px);
      }
    }
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-5));
    }
  
    &[data-active] {
      z-index: 2;
      background-color: var(--mantine-color-blue-filled);
      border-color: var(--mantine-color-blue-filled);
      color: var(--mantine-color-white);
  
    &:hover {
        background-color: var(--mantine-color-blue-filled-hover);
    }
        /* background-color: var(--mantine-color-blue-filled-hover);
      } */
    }
  }


  /* .box {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  } */

  .modalBody{
    padding: 0;
  }